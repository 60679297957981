// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Header from './components/Header';
import { farmBackground, farmLogo } from './assets';
import Products from './pages/Products/Products';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import Footer from './components/Footer';

const App: React.FC = () => {
  const initialProduct = {
    name: "Mango",
    category: "Fruits",
    description: "Support the Aleph Zero core team through community and marketing initiatives.",
    types: [
      { name: "Yasmina", color: "#2196f3" },
      { name: "Naaom", color: "#00eac7" },
      { name: "Awees", color: "#a6f074" },
      { name: "Crimson", color: "#03d1b5" },
      { name: "Osten", color: "#4e8c00" },
      { name: "Keitt", color: "#00eac7" },
    ],
  };
  return (
    <Router>
      <LoadingScreen images={[farmLogo, farmBackground]}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ProductDetail />} /> {/* Product detail route */}

        </Routes>
        <Footer />
      </LoadingScreen>
    </Router>
  );
};

export default App;
